'use client'

import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

const isDevelopment = process.env.NODE_ENV === 'development'

const SentryInit = ({
  children,
}: { children: React.ReactElement }) => {
  useEffect(() => {
    const SENTRY_DSN = document?.body?.getAttribute('data-public-sentry-dsn')
    const DEPLOY_ENV = document?.body?.getAttribute('data-public-deploy-env') ?? 'DEV'
    const CURRENT_UNIXYZ_TAG = document?.body?.getAttribute('data-public-current-unixyz-tag') ?? 'v1.0.0'
    const COMMIT_SHA = document?.body?.getAttribute('data-public-commit-sha') ?? '666666666'

    if (!isDevelopment && SENTRY_DSN) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.captureConsoleIntegration({ levels: ['error'] }),
          Sentry.replayIntegration({
            networkDetailAllowUrls: [
              window.location.origin,
            ],
            networkRequestHeaders: ['Authorization', 'X-Request-Id'],
          }),
        ],
        sampleRate: DEPLOY_ENV === 'PRODUCTION' ? 1.0 : 0.1,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.01,
        environment: DEPLOY_ENV,
        release: `${CURRENT_UNIXYZ_TAG}-${COMMIT_SHA}`,
      })
    }
  }, [])
  return children
}

export default SentryInit
